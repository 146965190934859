import Iconify from '../../../components/iconify';
import { PATH_DASHBOARD, PATH_MANAGER } from '../../../routes/paths';

const navConfig = [
  {
    subheader: 'Gerenciamento',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: <Iconify icon="clarity:dashboard-solid" />,
      },
    ],
  },
  {
    subheader: 'Páginas',
    items: [
      {
        title: 'homepage',
        path: PATH_DASHBOARD.general.app,
        icon: <Iconify icon="material-symbols:home-rounded" />,
      },
      {
        title: 'Notícias',
        path: PATH_MANAGER.news,
        icon: <Iconify icon="material-symbols:news" />,
        children: [
          { title: 'Nova Publicação', path: PATH_DASHBOARD.blog.posts },
          { title: 'Editar Publicação', path: PATH_DASHBOARD.blog.demoView },
        ],
      },
      {
        title: 'Vestibular',
        path: PATH_DASHBOARD.general.app,
        icon: <Iconify icon="material-symbols:home-rounded" />,
      },

      {
        title: 'Fale Conosco',
        path: PATH_DASHBOARD.general.app,
        icon: <Iconify icon="material-symbols:home-rounded" />,
      },
    ],
  },
];

export default navConfig;
