import { Box, Card, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthContext } from '../../auth/useAuthContext';
import LoginLayout from '../../layouts/login';
import { PATH_CONTACT } from '../../routes/paths';
import AuthLoginForm from './AuthLoginForm';

// --------------------------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Card style={{ width: '500px', backgroundColor: 'white', padding: 30 }}>
        <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
          <Typography variant="h4">Painel Administrativo FASA</Typography>

          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2">Não consegue logar?</Typography>

            <Link component={RouterLink} to={PATH_CONTACT.fullcode} variant="subtitle2">
              Entre em contato
            </Link>
          </Stack>

          <Box
            component="img"
            alt={method}
            src="/images/logo.png"
            sx={{ width: 64, height: 64, position: 'absolute', right: 0 }}
          />
        </Stack>

        <AuthLoginForm />
      </Card>
    </LoginLayout>
  );
}
